<template>
  <v-container fluid>
    <h2 class="title-style">
      Dashboard 📈
    </h2>
    <v-row>
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col cols="12">
            <v-card class="pa-8 welcome_card">
              <div>
                <img
                  src="../../assets/icons/sunicon.png"
                  alt=""
                  class="greeting-icon"
                >
                <h4 class="greeting">
                  {{ greeting }} !
                </h4>
                <div class="d-flex name_div">
                  <h1 class="name">
                    {{ userProfile.first_name }} {{ userProfile.last_name }}
                  </h1>
                  <img
                    src="../../assets/icons/poppericon.png"
                    alt=""
                    width="36px"
                  >
                </div>
                <h3 class="date">
                  {{ today }}
                </h3>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <dashboard-data-usage />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="7"
        class="mt-7"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <dashboard-project-count />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <dashboard-app-user-count />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <dashboard-sus-checkin-count />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <dashboard-least-active-users />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <dashboard-active-checkins />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card class="pa-8 dashboard_cards upgrade_card">
              <h2>Upgrade your plan</h2>
              <p>
                Your plan is in Trial, <br>
                contact us for unlock more features
              </p>
              <button class="contact_us_btn">
                Contact us
              </button>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center">
          <h1>Analytics</h1>
          <img
            src="../../assets/icons/analytics.png"
            alt=""
            height="25px"
            width="25px"
            class="ml-2"
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-card class="chart_card pa-8">
          <h3>Daily Check-In Activities</h3>
          <current-checkins />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card class="chart_card pa-8">
          <h3>Project Issues</h3>
          <project-issue-counts />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card class="chart_card pa-8">
          <h3>Longest Project Delays</h3>
          <longest-project-delays />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DashboardDataUsage from './dashboardnew/DashboardDataUsage.vue';
import DashboardProjectCount from './dashboardnew/DashboardProjectCount.vue';
import DashboardAppuserCount from './dashboardnew/DashboardAppuserCount.vue';
import DashboardSusCheckinCount from './dashboardnew/DashboardSusCheckinCount.vue';
import DashboardLeastActiveUsers from './dashboardnew/DashboardLeastActiveUsers.vue';
import DashboardActiveCheckins from './dashboardnew/DashboardActiveCheckins.vue';
import CurrentCheckins from './dashboardnew/charts/CurrentCheckins.vue';
import LongestProjectDelays from './dashboardnew/charts/LongestProjectDelays.vue';
import ProjectIssueCounts from './dashboardnew/charts/ProjectIssueCounts.vue';
import moment from 'moment';
export default {
  name: 'DashboardNew',
  components: {
    'dashboard-data-usage': DashboardDataUsage,
    'dashboard-project-count': DashboardProjectCount,
    'dashboard-app-user-count': DashboardAppuserCount,
    'dashboard-sus-checkin-count': DashboardSusCheckinCount,
    'dashboard-least-active-users': DashboardLeastActiveUsers,
    'dashboard-active-checkins': DashboardActiveCheckins,
    'current-checkins': CurrentCheckins,
    'project-issue-counts': ProjectIssueCounts,
    'longest-project-delays': LongestProjectDelays,
  },
  data () {
    return {
      loading: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: '#000',
            fontsize: 14,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: '#000',
                stepSize: 50,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'My Sites',
                fontColor: '#000',
              },
              ticks: {
                display: false,
                fontColor: '#000',
              },
            },
          ],
        },
      },
      today: moment().format('MMMM Do YYYY'),
    };
  },
  computed: {
    userProfile () {
      return this.$store.getters['userprofile/getUserProfile'];
    },
    greeting () {
      const hour = new Date().getHours();
      if (hour < 12) {
        return 'Good Morning';
      } else if (hour < 18) {
        return 'Good Afternoon';
      } else {
        return 'Good Evening';
      }
    },
  },
  mounted () {
    this.getUser();
  },
  methods: {
    async getUser () {
      this.loading = true;
      await this.$store.dispatch('userprofile/fetchUserProfile').catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474f;
  margin-top: 30px;
  font-size: 36px;
  font-weight: 600;
}
.v-main__wrap {
  background: #fff !important;
}
.name {
  font-size: 35px;
  background: linear-gradient(to right, #524ffc, #d01ebe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 260px;
  font-weight: bold;
}
.date {
  font-size: 26px;
  font-weight: normal;
}
.greeting {
  font-size: 16px;
  font-weight: normal;
}
.greeting-icon {
  position: absolute;
  right: 50px;
  top: 50px;
  width: 70px;
}
.welcome_card {
  display: flex;
  align-items: center;
  min-height: 421px;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 0px !important;
  position: relative;
}
.name_div {
  justify-content: center;
  align-items: center;
}

.dashboard_cards {
  min-height: 200px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.upgrade_card {
  background: linear-gradient(to right, #524ffc, #d01ebe);
  color: #fff;
}
.contact_us_btn {
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px 10px;
}
.dashboard_cards h4 {
  font-size: 22px;
  font-weight: 400;
  position: relative;
}
.dashboard_card_count {
  position: absolute;
  bottom: 10px;
}
.chart_card {
  border: 1px solid #ccc;
  box-shadow: none !important;
  border-radius: 10px;
}
</style>
